// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

// Import local dependencies
//import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import 'leaflet';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/* global L */
/* global FWP */

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  $(document).on('facetwp-loaded', () => {
    $('.matchHeight').matchHeight();
  });

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  $(
    '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  ).fancybox({
    minHeight: 0,
    helpers: {
      overlay: {
        locked: false,
      },
    },
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }

      $(this).toggleClass(
        'chosen',
        $(this).attr('type') === 'file' && $(this).val().length
      );
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        // TODO: replace with bootstrap toggle
        // $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  // Open JG Menu content
  $(document).on('click', '.js-menu-open', (e) => {
    e.preventDefault();
    e.stopPropagation();

    $(document).find('.js-menu-open').toggleClass('is-active');
    $(document).find('#jg-menu').toggleClass('is-open');
  });

  // Menu filters toggle
  $(document).on('click', '.js-filter-toggle', (e) => {
    e.preventDefault();
    $(document).find('.js-menu-filters').toggleClass('is-open');
    $(document).find('body').toggleClass('overflow-hidden');
  });

  // Open menu by category
  $(document).on('click', '.js-category-anchor', () => {
    $(document).find('.js-menu-open:not(.is-active)').trigger('click');
  });

  // Set dropdown menu background height
  $('#primary-menu').on('show.bs.dropdown', '.dropdown', function (e) {
    let $target = $(e.target).find('.dropdown-menu');
    $target.siblings('.dropdown-menu-bg').height($target.outerHeight());
  });

  // Open dropdown link by second click
  $('.navbar-nav > li > a').click(function () {
    if ($(this).parent().hasClass('show')) {
      location.href = this.href;
    }
  });

  // Open desktop menu on hover
  if ($(window).width() > 991) {
    $('#primary-menu .dropdown')
      .on('mouseenter', (e) => {
        $(e.target).closest('.dropdown').trigger('show.bs.dropdown');
        $(e.target).closest('.dropdown').addClass('show');
        $(e.target).siblings('.dropdown-menu').addClass('show');
      })
      .on('mouseleave', (e) => {
        $(e.target).closest('.dropdown').removeClass('show');
        $(e.target).siblings('.dropdown-menu').removeClass('show');
      });
  }

  initSliders();
  resizeVideo();
  initLeaflet();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  $(document)
    .find('img')
    .each(function () {
      $(this).attr('src', $(this).data('lazy-src')).addClass('lazyloaded');
    });

  $(document)
    .find('[data-bg]')
    .each(function () {
      $(this).css('background-image', 'url(' + $(this).data('bg') + ')');
    });

  // FacetWP Sort filtering
  $('.js-facet-sort').on('click', '.dropdown-item', function (e) {
    e.preventDefault();

    $(this)
      .closest('.js-facet-sort')
      .find('.dropdown-toggle')
      .text($(this).text());

    if (!$(this).data('value')) {
      $(this).remove();
    }

    if (typeof FWP !== 'undefined') {
      FWP.facets['ordering'] = [$(this).data('value')];
      FWP.fetchData();
      FWP.setHash();
    }
  });

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
let lastScrollTop = 0;
$(window).on('scroll', function () {
  // jQuery code goes here
  let st = $(this).scrollTop();
  $('html')
    .toggleClass('scrolling-top', st < lastScrollTop)
    .toggleClass('scrolling-bottom', st > lastScrollTop);
  if (st === 0) $('html').removeClass('scrolling-top scrolling-bottom');
  lastScrollTop = st;
});

// Anchor click scrolling
$(document).on('click', 'a[href^="#"]', function (e) {
  if (
    $(this).attr('href') != '#' &&
    !$(this).hasClass('fancybox') &&
    $(this).attr('role') != 'tab'
  ) {
    e.preventDefault();
    let $this = $(this);
    if ($($this.attr('href')).length) {
      $('html, body').animate(
        {
          scrollTop: $($this.attr('href')).offset().top - 60 + 'px',
        },
        750,
        'swing'
      );
    }
  }
});

// Get user current location coords (lat/lng)
if (window.navigator.geolocation && $('.flex_map').length) {
  window.navigator.geolocation.getCurrentPosition(getUserPosition);
}

// On load *only* if we have anchor on the url
if (window.location.hash) {
  // smooth scroll to the anchor id
  $('html, body').animate(
    {
      scrollTop: $(window.location.hash).offset().top - 60 + 'px',
    },
    750,
    'swing'
  );
}

function initSliders() {
  $('.js-testimonial-slider').each(function (index, el) {
    let toShow = $(el).data('show') ?? 3;
    let isAutoplay = $(this).data('autoplay') ? true : false;

    $(el).slick({
      slidesToShow: toShow,
      slidesToScroll: 1,
      speed: 1000,
      autoplay: isAutoplay,
      autoplaySpeed: 4000,
      adaptiveHeight: true,
      appendArrows: $(el).next('.js-testimonial-arrows'),
      prevArrow: '<i class="fas fa-angle-double-left"></i>',
      nextArrow: '<i class="fas fa-angle-double-right"></i>',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: toShow > 2 ? 2 : toShow,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  });

  $('.js-products-slider').each(function (index, el) {
    let toShow = $(el).data('show') ?? 3;

    $(el).on('init', () => {
      $('.product_item__title').matchHeight();
      $('.product_item__description').matchHeight();
    });

    $(el).slick({
      slidesToShow: toShow,
      slidesToScroll: 1,
      speed: 1000,
      adaptiveHeight: true,
      appendArrows: $(el).next('.js-products-arrows'),
      prevArrow: '<i class="fas fa-angle-double-left"></i>',
      nextArrow: '<i class="fas fa-angle-double-right"></i>',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: toShow > 2 ? 2 : toShow,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  });
}

function initLeaflet() {
  $('.leaflet-map').each(function () {
    let dataId = $(this).attr('data-id');
    let dataMapAddress = $(this).attr('data-map-address');
    let dataDirection = $(this).attr('data-direction');
    let dataMapLat = $(this).attr('data-map-lat');
    let dataMapLng = $(this).attr('data-map-lng');
    let dataMapZoom = $(this).attr('data-map-zoom');
    let dataMarkerUrl = $(this).attr('data-marker-url');
    let dataMarkerWidth = parseInt($(this).attr('data-marker-width'));
    let dataMarkerHeight = parseInt($(this).attr('data-marker-height'));
    let popupContent = dataMapAddress;
    let directionLink = 'https://www.google.com/maps/dir/';

    let map = L.map(dataId, {
      zoomControl: false,
      center: [dataMapLat, dataMapLng],
      zoom: dataMapZoom,
    });
    L.tileLayer(
      'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
      {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        subdomains: 'abcd',
        maxZoom: 20,
      }
    ).addTo(map);
    let myIcon = L.icon({
      iconUrl: dataMarkerUrl,
      iconSize: [dataMarkerWidth, dataMarkerHeight],
      iconAnchor: [dataMarkerWidth / 2, dataMarkerHeight],
      popupAnchor: [0, -(dataMarkerHeight - 3)],
    });
    let marker;
    if (dataMarkerUrl !== 'false') {
      marker = L.marker(new L.LatLng(dataMapLat, dataMapLng), {
        icon: myIcon,
        title: dataMapAddress,
      }).addTo(map);
    } else {
      marker = L.marker(new L.LatLng(dataMapLat, dataMapLng), {
        title: dataMapAddress,
      }).addTo(map);
    }

    /*if ($(document).find('body').data('coords')) {
      directionLink += $('body').data('coords') + '/' + dataMapAddress;
    }*/

    if (dataDirection) {
      popupContent +=
        '<a class="js-map-direction d-block my-2" href="' +
        directionLink +
        '" target="_blank">' +
        dataDirection +
        '</a>';
    }

    marker.bindPopup(popupContent);
    marker.on('popupopen', function () {
      let mapDirectionLink = $(document).find('.js-map-direction');
      if (mapDirectionLink.length && $('body').data('coords')) {
        mapDirectionLink.attr(
          'href',
          mapDirectionLink.attr('href') +
            $('body').data('coords') +
            '/' +
            dataMapAddress
        );
      }
    });
  });
}

function getUserPosition(position) {
  $('body').attr(
    'data-coords',
    position.coords.latitude + ',' + position.coords.longitude
  );
}
